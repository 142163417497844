define("discourse/plugins/discourse-education-group-custom-fields/discourse/templates/connectors/group-edit/field-container", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <!-- STEP 1. This is where we get the user to input the data. Note that
  this template will be inserted into the groups-form-profile-fields component template
  which is used in both the group creation and group editing UIs. This means this input
  will be available both when creating and editing a group -->
  
  <div class="control-group">
    {{input type="checkbox" checked=group.custom_fields.my_field}}
    <span>{{i18n 'admin.groups.my_field.label'}}</span>
  </div>
  
  */
  {
    "id": "99fMfWrH",
    "block": "[[[3,\" STEP 1. This is where we get the user to input the data. Note that\\nthis template will be inserted into the groups-form-profile-fields component template\\nwhich is used in both the group creation and group editing UIs. This means this input\\nwill be available both when creating and editing a group \"],[1,\"\\n\\n\"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"group\",\"custom_fields\",\"my_field\"]]]]]],[1,\"\\n  \"],[10,1],[12],[1,[28,[35,1],[\"admin.groups.my_field.label\"],null]],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `group` property path was used in the `discourse/plugins/discourse-education-group-custom-fields/discourse/templates/connectors/group-edit/field-container.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.group}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"input\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-education-group-custom-fields/discourse/templates/connectors/group-edit/field-container.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});